export const variables: Variables = {
    api: {
        dealers: {
            city: '',
            make: '',
            stateCode: '',
            photoLocation: '',
        },
    },

    arkonas: {
        site: 'cyd',
        fleet: '',
        sales: '',
        service: '',
        trade: '',

        new: 'store',
        used: 'store',
    },

    hub: {
        isHubSite: false,

        isAz: false,
        isChoice: false,
        isTucson: false,
        isValue: false,
        location: '',
    },

    dealerShort: 'Chapman Dodge Yuma',
    isUsedSite: false,

    appointmentOptions: {
        reverse: false,
    },

    breadcrumbsBarOptions: {
        uppercase: true,
    },

    carLinkOptions: {
        scrollToDisclaimer: false,
    },

    certificationOptions: {
        gtm: {
            pageTrackingEvent: 'nuxtRoute',
        },
    },

    chipsDisplayOptions: {
        usedLabel: 'Used',
    },

    clearanceOptions: {
        year: 2020,
        make: '',
    },

    communityOptions: {
        grayBgIndex: 'even',
    },

    detailOptions: {
        hideEstimatesBehindGate: false,
        showVdpDealerDetailsByCar: false,

        windowStickerUrls: {
            new: {
                display:
                    'https://www.chrysler.com/hostd/windowsticker/getWindowStickerPdf.do?vin=[vin]',
            },
            used: {
                display: '',
            },
        },

        isVdpGated: true,
        includeCustomRateOption: false,
    },

    filtersOptions: {
        defaultToPopularitySort: false,
        disabledCategories: [],
        typeLabel: '',
        usedLabel: 'Used',
    },

    footerFormOptions: {
        centerText: false,
        flavorText: '',
    },

    footerOptions: {
        showBbbSeal: false,
    },

    headingOptions: {
        splitName: ['Chapman', 'Dodge Chrysler Jeep Ram'],
        uppercase: true,
        useFullWidthBar: false,
    },

    homeSearchRefineOptions: {
        flavor: 'Refine your search to find the vehicle you are looking for',
        uppercase: true,
        usedLabel: 'Used',
    },

    infoBanners: [],

    inventory: {
        sortMakeToTop: '',
    },

    keys: {
        maps: 'AIzaSyAoOHEKP1Taainx2gRt1clS1b5AVHUC9JE',
        careers: 'chapmandcjryuma',
    },

    layoutOptions: {
        hiddenBackToTopPages: [
            { name: 'service' },
            { name: 'service-jeep-wave' },
            { name: 'service-express' },
            { name: 'service-protection' },
            { name: 'service-coupons' },
            { name: 'detail-type-year-make-model-stock-uniqueArkona' },
        ],
        hiddenFooterFormPages: [
            { name: 'contact' },
            { name: 'parts' },
            { name: 'service-jeep-wave' },
            { name: 'careers-sales' },
            { name: 'careers-service' },
            { name: 'careers-admin' },
            '/form',
        ],
    },

    navOptions: {
        hideMakeModelLinks: false,
        hideMake: false,
    },

    pricingSectionOptions: {
        scrollToDisclaimer: false,
    },

    reviewLinks: {
        google: 'https://www.google.com/maps/place/Chapman+Dodge+Chrysler+Jeep+Ram/@32.6686759,-114.6205776,17z/data=!4m7!3m6!1s0x80d65f4b2f87cd5b:0xe8552539d7c9cd31!8m2!3d32.6687284!4d-114.6183892!9m1!1b1',
        yelp: 'https://www.yelp.com/biz/chapman-chrysler-dodge-jeep-ram-yuma',
    },

    searchOptions: {
        cpoFilterByMake: false,
        searchCards: [],
        matchingCards: [],
    },

    seoOptions: {
        city: '',
        name: '',
        region: 'Yuma',
        seoDealerId: 37,
        state: '',
        stateShort: '',
        url: '',
    },

    serviceOptions: {
        externalSchedulingUrl:
            'https://conscheduling.tekioncloud.com/consumer-scheduling/sign-in/phone?accessToken=chapmanautomotivegroup_72_1065',
    },

    specialsOptions: {
        new: {
            useNational: false,
        },
        used: {
            useDay45: true,
            limit: 10,
            label: 'Used',
            filterByMake: false,
        },
        cpo: {
            useDay45: true,
            filterByMake: false,
        },
    },

    staffOptions: {
        showContactDetails: true,

        specialDepartments: [
            {
                label: 'Management',
                positionFilter: 'Manager',
                objectKeyFilter: 'isManager',
                orderNumber: 1,
            },
        ],
    },

    tagOptions: {
        grayscale: false,
    },

    theme: 'default',
};
